import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ItemCategory } from '@common/services/commondata';
import { oDataService } from '@common/services/odata';
import { Pagination } from '@common/types/pagination.types';
import { environment } from 'environments/environment';
import { BehaviorSubject, forkJoin, Observable, tap, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ItemCategoryService {
    // Private
    private _filters: BehaviorSubject<any> = new BehaviorSubject({});
    private _pagination: BehaviorSubject<Pagination | null> = new BehaviorSubject(null);
    private _product: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _products: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
    private _itemCategory: BehaviorSubject<ItemCategory[]> = new BehaviorSubject<ItemCategory[]>(null);
    private controller: string = 'itemCategory';
    private withJoin: string = 'ItemCategoryVarianceManagement($select=Id,LevelOne)';
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _oDataService: oDataService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set BehaviorSubject ItemCategory
     * @param value
     */
    setItemCategory$(value: ItemCategory[]) {
        this._itemCategory.next(value);
    }

    /**
     * Getter for filters
     */
    get filters() {
        return this._filters.value;
    }

    /**
     * Setter for filters
     */
    set filters(values: any) {
        this._filters.next(values);
    }

    /**
     * Getter for Pagination
     */
    get pagination() {
        return this._pagination.value;
    }

    /**
     * Setter for Pagination
     */
    set pagination(values) {
        this._pagination.next(values);
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<Pagination> {
        return this._pagination.asObservable();
    }

    /**
     * Getter for product
     */
    get product$(): Observable<any> {
        return this._product.asObservable();
    }

    /**
     * Getter for products
     */
    get products$(): Observable<any[]> {
        return this._products.asObservable();
    }

    /**
     * Getter for Observable ItemCategory
     */
    get itemCategory$(): Observable<ItemCategory[]> {
        return this._itemCategory.asObservable();
    }

    /**
     * Getter for ItemCategory
     */
    get itemCategory(): ItemCategory[] {
        return this._itemCategory.value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get products
     * @param reload
     */
    getProductsWithPagination(reload?: boolean): Observable<any> {
        if (reload) {
            this.pagination = {
                length: 0,
                page: 0,
                size: 100,
                sort: '',
                order: 'asc'
            }
            this.filters = {};
        }
        return forkJoin({
            products: this.getProducts(this.filters, this.pagination,),
        }).pipe(
            tap((response) => {
                this._pagination.next({ ...this.pagination, length: response.products["@odata.count"] });
                this._products.next(response.products["value"]);
            })
        );
    }

    /**
     * Get products
     *
     * @param filters
     * @param pagination
     */
    getProducts(filters, pagination) {
        return this._httpClient.get<any[]>(`${environment.oDataUrl}/odata/` + this.controller + this._oDataService.setFilters(filters, pagination,this.withJoin));
    }

    getCommonData() {
        return this.getProducts({ isActive: true }, null).pipe(
             map((response) => {
                 this._itemCategory.next(response["value"]);
             })
         );
     }

    getAllItemCategories(): Observable<any> {        
        return this._httpClient.get<any[]>(
            `${environment.oDataUrl}/odata/` + this.controller );
    }
    /**
    /**
     * download products
     * 
     * @param filters 
     */
    downloadProducts(filters): Observable<any[]> {
        return this._httpClient.get<any[]>(`${environment.oDataUrl}/odata/` + this.controller
            + this._oDataService.setFilters(filters, null, this.withJoin))
            .pipe(
                map((response) => {
                    return response["value"];
                })
            );
    }

    /**
     * Upload product
     * 
     * @param products
     */
    uploadProduct(products) {
        return this._httpClient.post(`${environment.oDataUrl}/api/` + this.controller, products, {
            reportProgress: true,
            observe: 'events'
        });
    }

    /**
     * Create product
     * 
     * @param products
     */
    createProduct(products) {
        this._httpClient.post(`${environment.oDataUrl}/api/` + this.controller, products).subscribe(() => {
            forkJoin({
                items: this.getProductsWithPagination(),
                commondata: this.getCommonData()
            }).subscribe();
        });
    }

    /**
     * Update product
     *
     * @param product
     */
    updateProduct(id: string, product: any) {
        this._httpClient.put(`${environment.oDataUrl}/api/` + this.controller + `/${id}`, product).subscribe(() => {
            forkJoin({
                items: this.getProductsWithPagination(),
                commondata: this.getCommonData()
            }).subscribe();
        });
    }

    getItemCategoryIdByCode(code: string): number {

        let item = this.itemCategory.find(x => x.code == code);
        if (item == undefined || item.id == 0)
            return 0;
        return item.id;
    }
}
