import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ArticleDetails, Mall, Warehouse } from '@common/services/commondata';
import { oDataService } from '@common/services/odata';
import { Pagination } from '@common/types/pagination.types';
import { environment } from 'environments/environment';
import { BehaviorSubject, forkJoin, Observable, tap, map } from 'rxjs';
import _ from 'lodash';
import { TagService } from '../tag/tag.service';
import { SupplierService } from '../supplier/supplier.service';

@Injectable({providedIn: 'root'})
export class ItemMasterService
{
    // Private
    private _filtersArticle: BehaviorSubject<any> = new BehaviorSubject({});
    private _filtersSku: BehaviorSubject<any> = new BehaviorSubject({});

    private _paginationArticle: BehaviorSubject<Pagination> = new BehaviorSubject(null);
    private _paginationSku: BehaviorSubject<Pagination> = new BehaviorSubject(null);

    private _skus: BehaviorSubject<any[] | null> = new BehaviorSubject(null);

    private _articles: BehaviorSubject<any[] | null> = new BehaviorSubject(null);
    private controller: string = 'ItemMaster';
    private withJoinArticle: string = 'brand,ItemCategory,Supplier,ItemStyle,TagArticle($expand=tag)';
    private withJoinSku: string = 'article($expand=Brand,ItemCategory,Supplier,ItemStyle,TagArticle($expand=tag)),ItemCountrySellPrices($expand=SellPrice,CountryCurrency),CountryCurrency,PriceCategory,ItemSize,Color';
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _oDataService: oDataService,
        private TagService: TagService,
        private SupplierService: SupplierService,
        )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for filters
     */
    get filtersArticle() {
        return this._filtersArticle.value;
    }

    /**
     * Setter for filters
     */
    set filtersArticle(values: any) {
        this._filtersArticle.next(values);
    }

    /**
     * Getter for SkU filters
     */
    get filtersSku() {
        return this._filtersSku.value;
    }

    /**
     * Setter for SKU filters
     */
    set filtersSku(values: any) {
        this._filtersSku.next(values);
    }

    get paginationArticle() {
        return this._paginationArticle.value;
    }

    get paginationArticle$(): Observable<Pagination> {
        return this._paginationArticle.asObservable();
    }

    /**
     * Setter for Pagination
     */
    set paginationArticle(values) {
        this._paginationArticle.next(values);
    }

    get paginationSku() {
        return this._paginationSku.value;
    }

    get paginationSku$(): Observable<Pagination>
    {
        return this._paginationSku.asObservable();
    }

    set paginationSku(values) {
        this._paginationSku.next(values);
    }

    /**
     * Getter for Obeservable products
     */
    get skus$(): Observable<any[]>
    {
        return this._skus.asObservable();
    }

    get articles$(): Observable<any[]>
    {
        return this._articles.asObservable();
    }

    /**
     * Getter for skus
     */
    get skus(): any[] {
        return this._skus.value;
    }

    /**
     * Getter for articles
     */
    get articles(): any[] {
        return this._articles.value;
    }

    /**
     * Set BehaviorSubject Articles
     * @param value
     */
    setArticles$(value: any[]) {
        this._articles.next(value);
    }

    /**
     * Set BehaviorSubject SKUs
     * @param value
     */
    setSkus$(value: any[]) {
        this._skus.next(value);
    }
    
    /**
     * Get skus
     * @param reload
     */
    getSkusWithPagination(reload?: boolean): Observable<any> {
        if(reload) {
            this.paginationSku = {
                length: 0,
                page: 0,
                size: 100,
                sort: 'createdOn',
                order: 'desc'
            }
            this.filtersSku = {};
        }
        return forkJoin({
            products: this.getSkus(this.filtersSku, this.paginationSku),
        }).pipe(
            tap((response) => {
                this._paginationSku.next({ ...this.paginationSku, length: response.products["@odata.count"] });
                this._skus.next(response.products["value"]);
            })
        );
    }

    /**
     * Get articles
     *
     * @param filters
     * @param pagination
     */
    getArticlesWithPagination(reload?: boolean): Observable<any> {
        if(reload) {
            this.paginationArticle = {
                length: 0,
                page: 0,
                size: 100,
                sort: 'createdOn',
                order: 'desc'
            }
            this.filtersArticle = {};
        }
        return forkJoin({
            products: this.getArticles(this.filtersArticle, this.paginationArticle),
        }).pipe(
            tap((response) => {
                this._paginationArticle.next({ ...this.paginationArticle, length: response.products["@odata.count"] });
                this._articles.next(response.products["value"]);
            })
        );
    }

    getSkus(filters, pagination) {
        pagination.sort='createdOn';
        pagination.order="desc";
        return this._httpClient.get<any[]>(`${environment.oDataUrl}/odata/sku/${this._oDataService.setFilters(filters, pagination, this.withJoinSku)}`);
    }

    getArticles(filters, pagination) {
        pagination.sort='createdOn';
        pagination.order="desc";
        return this._httpClient.get<any[]>(`${environment.oDataUrl}/odata/article/${this._oDataService.setFilters(filters, pagination, this.withJoinArticle)}`);
    }

    getArticlesByBrandId(brandId:number) {
        return this._httpClient.get<any[]>(`${environment.oDataUrl}/api/article/GetArticleByBrand/${brandId}`);
    }

    getAllArticleNo() {
        return this._httpClient.get<any[]>(`${environment.oDataUrl}/api/article/articlenumbers`);
    }
    getArticleNoAndColors() {
        return this._httpClient.get<any[]>(`${environment.oDataUrl}/api/article/GetAllArticleNumbersAndColors`);
    }


    getArticleByNumber(number: any) {
        return this._httpClient.get<any>(`${environment.oDataUrl}/api/article/GetArticleByNumber?number=` + number);
    }

    getArticlesByNumbers(numbers: any) {
        return this._httpClient.post<any>(`${environment.oDataUrl}/api/article/getArticlesByNumbers`, numbers);
    }

    getArticleDetailsById(id : number) {
        return this._httpClient.get<any>(`${environment.oDataUrl}/api/${this.controller}/${id}`);
    }

    /**
     * download Articles
     * 
     * @param filters 
     */
    downloadArticles(filters): Observable<any[]> {
        return this._httpClient.get<any[]>(`${environment.oDataUrl}/odata/article/${this._oDataService.setFilters(filters, null, this.withJoinArticle)}`)
            .pipe(
                map((response) => {
                    let data = [];
                    response["value"].forEach(element => {
                        let item = { ...element };
                        item.tagArticle = element.tagArticle.map(t => t.tag.name).join(', ');
                        data.push(item);
                    });
                    return data;
                })
            );
    }

    /**
     * Upload articles
     * 
     * @param articles
     */
    uploadArticles(products) {
        let articles: any[] = products.map(element => {
            let item = { ...element };
            let tags = item.tagArticle.split(',');
            item.tagArticles = tags.map((x: string)=> { return this.TagService.getTagIdByName(x)});
            item.supplierId = this.SupplierService.getSupplierIdByCodeBrand(item["supplier.code"],item.brandId);
            delete item["supplier.code"];
            delete item.tagArticle;
            return item;
        });

        return this._httpClient.post(`${environment.oDataUrl}/api/${this.controller}/Article`, articles, {
            reportProgress: true,
            observe: 'events'
        });
    }

    createArticle(article) : Observable<any> {
        return this._httpClient.post(`${environment.oDataUrl}/api/Article`, article);
    }

    updateArticle(article) : Observable<any> {
        return this._httpClient.put(`${environment.oDataUrl}/api/Article/${article['id']}`, article);
    }

    updateSkus(articleDetails : ArticleDetails) : Observable<any> {
        return this._httpClient.put(`${environment.oDataUrl}/api/sku/${articleDetails.id}`, articleDetails.itemMasters);
    }

    /**
     * download SKUs
     * 
     * @param filters 
     */
    downloadSkus(filters): Observable<any[]> {
        return this._httpClient.get<any[]>(`${environment.oDataUrl}/odata/sku/${this._oDataService.setFilters(filters, null, this.withJoinSku)}`)
            .pipe(
                map((response) => {
                    let data = [];
                    response["value"].forEach(element => {
                        let item = { ...element };
                        item.article.tagArticle = element.article.tagArticle.map(t => t.tag.name).join(', ');
                        item.costPrice = item.countryCurrency.symbol + item.costPrice;
                        item.itemCountrySellPrices = item.itemCountrySellPrices.map(s => s.countryCurrency.symbol + s.sellPrice.price + s.countryCurrency.countryCode).join(', ');
                        data.push(item);
                    });
                    return data;
                })
            );
    }

    uploadSkus(products) {
        return this._httpClient.post(`${environment.oDataUrl}/api/${this.controller}/Sku`, products, {
            reportProgress: true,
            observe: 'events'
        })
    }

    /**
     * Create product
     * 
     * @param products
     */
    createProduct(products) {
        this._httpClient.post(`${environment.oDataUrl}/api/` + this.controller, products).subscribe(() => {
            this.getSkusWithPagination().subscribe();
        });
    }

    /**
     * Update product
     *
     * @param product
     */
    updateProduct(id: string, product: any) {
        this._httpClient.put(`${environment.oDataUrl}/api/` + this.controller + `/${id}`, product).subscribe(() => {
            this.getSkusWithPagination().subscribe();
        });
    }

    getArticleIdByNumber(articleNos: any[], articleNo: string): number {
        let item = articleNos.find(x => x.number == articleNo)
        if (item == undefined || item.articleId == 0)
            return 0;
        return item.articleId;
    }
}
