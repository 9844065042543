export interface ImageInfo {
    id?: string;
    imageUrl: string;
    imageSize: string;
    subSection: string;
}

export interface CountryCurrency {
    id: number;
    countryCode: string;
    countryName: string;
    currencyName: string;
    currencyCode: string;
    symbol: string;
}

export interface PrintedTag {
    epc: string;
    sku: string;
}

export interface Brand {
    id: number;
    levelOne: string;
    levelTwo: string;
    code: string;
}

export interface Mall {
    id: number;
    name: string;
    countryCurrencyId: number;
}

export interface Color {
    id: number;
    family: string;
    name: string;
    code: string;
    number: number;
}

export interface Warehouse {
    id: number;
    locationCode: string;
    levelOne: string;
    levelTwo: string;
    levelThree: string;
    countryCurrencyId: number;
}

export interface Supplier {
    id: number;
    code: string;
    name: string;
    brandId: number;
    countryId?: number;
    currencyId?: number;
}

export interface Store {
    id: number;
    mallId: number;
    name: string;
    code: string;
    size: string;
    address: string;
    mall:Mall;
    brands: Brand[];
}

export interface Location {
    id: number;
    name: string;
    type: string;
}

export interface ItemStyle {
    id: number;
    name: string;
}

export interface ItemSize {
    id: number;
    code: string;
    multiplier: number;
    isAutoSelected: boolean;
}

export interface ItemCategory {
    id: number;
    levelOne: number;
    levelTwo: string;
    name: string;
    code: string;
}

export interface ItemCategoryVariance {
    id: number;
    levelOne: string;
    variancePercentage:number;
    isActive:boolean;
}
export interface PriceCategory {
    id: number;
    name: string;
    countrySellPrices: ItemCountrySellPrice[];
}

export interface Collection {
    id: number;
    collectionName: string;
}

export interface ArticleDetails {
    itemName?: string;
    id?: number;
    brandCode?: string;
    brandId?: number;
    itemCategoryId?: number;
    categoryName?: string;
    itemStyleId?: number;
    itemStyleName?: string;
    number?: string;
    remarks?: string;
    sampleSource?: string;
    materialComposition?:string
    supplierId?: number;
    supplierCode?: string;
    supplierItemCode?: string;
    tags?: TagArticle[];
    itemMasters?: ItemMaster[];
    isActive?: boolean;
}

export interface ItemMaster {
    itemMasterId: number;
    colorId: number;
    colorName: string;
    countryCurrencyId: number;
    costCurrency: string;
    costCurrencySymbol: string;
    costPrice: number;
    itemSizeId: number;
    priceCategoryId: number;
    priceCategoryName: string;
    size: string;
    skuCode: string;
    itemCountrySellPrices: ItemCountrySellPrice[];
    isActive: boolean;
}

export interface ItemCountrySellPrice {
    itemCountrySellPriceId: number;
    countryCode: string;
    countryCurrencyId: number;
    symbol: string;
    price: number;
    sellPriceId?: number;
    countryName?: string;
}

export interface TagArticle {
    tagArticleId:number;
    tagId:number;
    name:string;
}

export interface Tag {
    id:number;
    name:string;
}

export interface ColorAndSize {
    itemMasterId;
    colorId: number;
    sizeId: number;
    colorName: string;
    sizeName: string;
}

export interface SkuTagGeneration {
    itemName?: string;
    skuCode?: string;
    poNumber?: string;
    purchaseOrderId?: number;
    purchaseOrderSizeId?:number;
    qty?: number;
    brandId?: number;
    collectionId?: number;
    itemCategoryId?: number;
    priceCategoryId?: number;
}

export interface PageErrors{
    currentPage?:string;
    inputQtyPagesErrors:inputQtyPageError[];
}

export interface inputQtyPageError{
    colorId:number;
    sizeId:number;
    storeId:number;
    addedQty:number;
}

export interface EpcRow
{
    check? : string;
    epc : string;
    poNUmber? : string;
    skuCode : string;
    itemName? : string;
    brandCode?:string;
    statusName? : string;
}

export interface UnexpectedEpcsInput {
    Epcs?: string[];
    PurchaseOrderIds?: number[];
}

export interface ExpectedEpcsInput
{
    checkedEPCs: string;
    purchaseOrderIds: string;
}
export interface CreateUnexpectedTagsReq {
    Epc: string
    skuCode: string
    processId: number
    processTypeCode: string
    locationId: number
    locationTypeCode: string
    poNumber: string
    status: string
}

export interface GoodsInboundRequestModel {
    palletId?:number;
    Epcs: string[];
    locationId : number;
    UnexpectedTags: CreateUnexpectedTagsReq[];
}

export interface StockAdjustmentReason {
    typeCode: string;
    reasonCode: string;
    reasonName: string;
}


