import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, forkJoin, Observable, tap, map, switchMap } from 'rxjs';
import _ from 'lodash';

import { oDataService } from '@common/services/odata';
import { Pagination } from '@common/types/pagination.types';
import { environment } from 'environments/environment';
import { Collection, ItemStyle } from '@common/services/commondata';
import { DatePipe } from '@angular/common';
import { DATE_FORMAT } from '@common/constants/const';
import { ItemMasterService } from '../itemmaster/itemmaster.service';

@Injectable({ providedIn: 'root' })
export class CollectionService {
    // Private
    private _filters: BehaviorSubject<any> = new BehaviorSubject({});
    private _pagination: BehaviorSubject<Pagination> = new BehaviorSubject(
        null
    );
    private _product: BehaviorSubject<any | null> = new BehaviorSubject(null);
    private _products: BehaviorSubject<any[] | null> = new BehaviorSubject(
        null
    );
    private _collectionArticles: BehaviorSubject<any[] | null> = new BehaviorSubject(
        null
    );
    private _collection: BehaviorSubject<Collection[]> = new BehaviorSubject<Collection[]>(null);

    private controller: string = 'Collection';
    private withJoin: string = 'brand,color';
    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private _oDataService: oDataService,
        private _itemMasterService: ItemMasterService,
        private datePipe: DatePipe
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for filters
     */
    get filters() {
        return this._filters.value;
    }

    /**
     * Setter for filters
     */
    set filters(values: any) {
        this._filters.next(values);
    }

    /**
     * Getter for Pagination
     */
    get pagination() {
        return this._pagination.value;
    }

    /**
     * Setter for Pagination
     */
    set pagination(values) {
        this._pagination.next(values);
    }

    /**
     * Getter for pagination
     */
    get pagination$(): Observable<Pagination> {
        return this._pagination.asObservable();
    }

    /**
     * Getter for product
     */
    get product$(): Observable<any> {
        return this._product.asObservable();
    }

    /**
     * Getter for products
     */
    get products$(): Observable<any[]> {
        return this._products.asObservable();
    }

    get collectionArticles$(): Observable<any[]> {
        return this._collectionArticles.asObservable();
    }

    /**
     * Getter for Observable collection
     */
    get collection$(): Observable<Collection[]> {
        return this._collection.asObservable();
    }

    /**
     * Getter for collection
     */
    get collection(): Collection[] {
        return this._collection.value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set BehaviorSubject Collection
     * @param value
     */
    setCollection$(value: Collection[]) {
        this._collection.next(value);
    }

    /**
     * Get products
     * @param reload
     */
    getProductsWithPagination(reload?: boolean): Observable<any> {
        if (reload) {
            this.pagination = {
                length: 0,
                page: 0,
                size: 100,
                sort: '',
                order: 'asc',
            };
            this.filters = {};
        }
        return forkJoin({
            products: this.getProducts(this.filters, this.pagination),
        }).pipe(
            tap((response) => {
                this._pagination.next({
                    ...this.pagination,
                    length: response.products['@odata.count'],
                });
                this._products.next(response.products['value']);
            })
        );
    }


    createNewCollection(req:any){
        return this._httpClient.post<any[]>(
            `${environment.oDataUrl}/api/` + this.controller + `/CreateCollection/`,req);
    }
    editCollection(id,req:any){
        return this._httpClient.put<any[]>(
            `${environment.oDataUrl}/api/` + this.controller + `/EditCollection/${id}`,req);
    }
    
    getAllCollections(): Observable<any> {        
        return this._httpClient.get<any[]>(
            `${environment.oDataUrl}/api/` + this.controller + `/GetAll` );
    }
    getCollectionArticles(){
        return this.getProducts({},null).pipe(
            map((response:any) => {
                this._collectionArticles.next(response.value);
            })
        );
    }

    getCommonData() {
        return this.getAllCollections().pipe(
             map((response) => {
                 this._collection.next(response);
             })
         );
     }

    /**
     * Get products
     *
     * @param filters
     * @param pagination
     */
    getProducts(filters, pagination) {
        return this._httpClient.get<any[]>(
            `${environment.oDataUrl}/odata/` +
                this.controller +
                this._oDataService.setFilters(
                    filters,
                    pagination,
                    this.withJoin
                )
        );
    }

    /**
     * download products
     *
     * @param filters
     */
    downloadProducts(filters): Observable<any[]> {
        return this._httpClient
            .get<any[]>(
                `${environment.oDataUrl}/odata/` +
                    this.controller +
                    this._oDataService.setFilters(filters, null, this.withJoin)
            )
            .pipe(
                map((response) => {
                    let data = [];
                    response["value"].forEach(element => {
                        let item = { ...element };
                        item.launchDate = this.datePipe.transform(item.launchDate, DATE_FORMAT);
                        // if (element.articles.length > 0) {
                        //     element.articles.forEach(childElement => {
                        //         item.articles = {
                        //             articleNumber: childElement.actualArticleNumber
                        //         };
                        //         item.color = {
                        //             name: childElement.color.name
                        //         };
                        //         data.push(Object.assign({}, item));
                        //     });
                        // }
                        // else {
                            data.push(item);
                        //}
                    });

                    return data;
                })
            );
    }

    /**
     * Upload product
     *
     * @param products
     */
    uploadProduct(products) {
        let uniqueItems = [...new Set(products.map(x => x["actualArticleNumber"]))];
        return this._itemMasterService.getArticlesByNumbers(uniqueItems)
            .pipe(switchMap((response) => {

                let data = _.chain(products)
                .groupBy('collectionName')
                .map((articles, collectionName) => {
                    let product = {
                        collectionName: collectionName,
                        articles: articles.map((x) => {
                            return {
                                articleNumber: this._itemMasterService.getArticleIdByNumber(response,x['actualArticleNumber']),
                                colorId: x.colorId,
                                launchDate: articles[0].launchDate,
                                isActive: articles[0].isActive,
                            };
                        }),
                 
                    };
                    return product;
                }).value();

            return this._httpClient.post(
                `${environment.oDataUrl}/api/` + this.controller+'/UploadCollectionArticles', data,
                {
                    reportProgress: true,
                    observe: 'events',
                }
            );
        }));
    }



    createArticleCollection(req){
        this._httpClient
            .post(`${environment.oDataUrl}/api/` + this.controller, req).subscribe(() => {
                forkJoin({
                    items: this.getProductsWithPagination(true),
                    commondata: this.getCommonData(),
                    collectionArticles:this.getCollectionArticles()
                }).subscribe();
            });
    }
    editArticleCollection(req){
        this._httpClient
            .put(`${environment.oDataUrl}/api/` + this.controller, req).subscribe(() => {
                forkJoin({
                    items: this.getProductsWithPagination(true),
                    commondata: this.getCommonData(),
                    collectionArticles:this.getCollectionArticles()
                }).subscribe();
            });
    }

    /**
     * Create product
     *
     * @param products
     */
    createProduct(products) {
        this._httpClient
            .post(`${environment.oDataUrl}/api/` + this.controller, products).subscribe(() => {
                forkJoin({
                    items: this.getProductsWithPagination(),
                    commondata: this.getCommonData()
                }).subscribe();
            });
    }

    /**
     * Update product
     *
     * @param product
     */
    updateProduct(id: string, product: any) {
        this._httpClient.put(`${environment.oDataUrl}/api/` + this.controller + `/${id}`,product).subscribe(() => {
            forkJoin({
                items: this.getProductsWithPagination(),
                commondata: this.getCommonData()
            }).subscribe();
        });
    }

    searchCollection(articleNumber: number, colorId: number) {
        return this._httpClient.get(
            `${environment.oDataUrl}/api/` +
                this.controller +
                `/searchCollection`,
            { params: { articleNumber, colorId } }
        );
    }
}
